import { render, staticRenderFns } from "./UserCenter.vue?vue&type=template&id=31ed4032&scoped=true&"
import script from "./UserCenter.vue?vue&type=script&lang=js&"
export * from "./UserCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ed4032",
  null
  
)

export default component.exports