<template>
  <div class="page_basic_container user_background center_col_container">
    <div class="center_col_container hover_shadow user_content" v-loading="loading">
      <template v-if="step===0">
        <span style="padding: 20px;padding-bottom:0px;font-size: 25px">找回密码</span>
        <span style="padding: 10px;padding-bottom:0px;font-size: 15px;color: #8c939d">
        使用用户名或注册邮箱即可找回密码，如仍无法解决可以联系网站管理员</span>
        <div class="space_between_row_container" style="width: 100%;margin-top: 40px;margin-bottom: 10px">
          <el-input v-model="user" placeholder="请输入注册邮箱或用户名"
                    style="width: 100%;margin: 10px;"></el-input>
          <el-button :disabled="user===''" style="margin: 10px;" type="primary" plain @click="findPass">
            确认
          </el-button>
        </div>
      </template>
      <template v-if="step===1">
        <span style="padding: 20px;padding-bottom:0px;font-size: 25px">请输入邮箱验证码</span>
        <span style="padding: 10px;padding-bottom:0px;font-size: 15px;color: #8c939d">
        一封包含验证码的邮件已经发送至你的邮箱~</span>
        <div class="space_between_row_container" style="width: 100%;margin-top: 40px;margin-bottom: 10px">
          <el-input v-model="code" placeholder="请输入邮箱验证码"
                    style="width: 100%;margin: 10px;"></el-input>
          <el-button :disabled="code===''" style="margin: 10px;" type="primary" plain @click="verifyCode">
            确认
          </el-button>

        </div>
      </template>
      <template v-if="step===2">
        <span style="padding: 20px;padding-bottom:0px;font-size: 25px">请重置你的密码</span>
        <span style="padding: 10px;padding-bottom:0px;font-size: 15px;color: #8c939d">
        尊敬的{{ entered_user }}，你的验证码已验证通过！请重置你的密码~</span>

        <div class="center_col_container" style="width: 100%;margin-top: 40px;margin-bottom: 10px">
          <el-input v-model="password" placeholder="密码" show-password style="width: 100%;margin: 10px;"></el-input>
          <el-input v-model="password_again" placeholder="再次确认密码" show-password
                    style="width: 100%;margin: 10px;"></el-input>
          <span v-if="error_hint!==''" style="color: red;margin-right: auto;font-size: 14px">* 提示：{{ error_hint }}</span>
          <el-button style="margin: 10px;" type="primary" plain @click="changePass">
            重置密码
          </el-button>
        </div>
      </template>
      <template v-if="step===3">
        <span style="padding: 20px;padding-bottom:0px;font-size: 25px">你已成功重置了密码！</span>
        <img src="../../assets/small_e/correct2.jpg" style="width: 55%;height: auto;margin-bottom: 20px">
        <el-button @click="login">前往登陆（{{ countdown }}）</el-button>
      </template>

    </div>
  </div>
</template>

<script>
import {change_pass, user_forget_pass, user_logout, verify_forget_code} from "@/utils/user_utils";
import {isPasswordAvailable} from "@/utils/utils";

export default {
  name: "UserForgetPass",
  data() {
    return {
      countdown: 5,
      user: "",
      entered_user: "",
      entered_code: "",
      loading: false,
      step: 0,
      code: "",
      password:"",
      password_again:"",
      error_hint:""
    }
  },
  mounted() {

  },
  methods: {
    login(){
      this.$router.push('/user/login')
    },
    startCountDown() {
      let that = this
      setInterval(function () {
        that.countdown -= 1
        if (that.countdown === 0) {
          that.$router.push("/user/login")
        }
      },1000)
    },
    findPass() {
      let data = {"user": this.user}
      this.entered_user = this.user
      this.user = ""
      user_forget_pass(data, this)
    },
    verifyCode() {
      let data = {"user": this.entered_user, "code": this.code}
      this.entered_code = this.code
      this.code = ""
      verify_forget_code(data, this)
    },
    changePass() {
      if (this.password !== this.password_again) {
        this.error_hint = "两次输入的密码不同！"
        this.password_again = ""
      } else if (!(this.password.length >= 8 && this.password.length <= 30)) {
        this.error_hint = "密码长度为8-20个字符！"
      } else if (!isPasswordAvailable(this.password)) {
        this.error_hint = "密码强度不足！（密码需由大写/小写字母/数字/特殊字符组成，任意3者即可）"
      } else {
        let data = {
          "user": this.entered_user,
          "code": this.entered_code,
          "password1": this.password,
          "password2": this.password_again
        }
        change_pass(data, this)
      }

    }

  }
}
</script>

<style scoped>

</style>