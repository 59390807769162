<template>
  <div class="page_basic_container user_background center_col_container">
    <div class="center_col_container hidden-sm-and-down"
         style="padding: 40px;margin: 20px;width: 35%;background-color: #f8f8f8;border-radius: 5px"
         v-if="!has_selected">
      <span style="font-size: 20px">你当前的身份为游客</span>
      <span style="color: #8c939d;font-size: 16px">请选择你的身份</span>
      <div class="space_between_start_row_container" style="padding: 20px">
        <div @click="selectRole('student')" class="center_col_container hover_shadow clickable_item identity_block">
          <img src="../../assets/small_e/correct2.jpg" style="width: 100%;height: auto;margin-bottom: 20px">
          <span style="font-size: 20px">我是学生</span>
        </div>
        <div @click="selectRole('teacher')" class="center_col_container hover_shadow clickable_item identity_block">
          <img src="../../assets/small_e/correct2.jpg" style="width: 100%;height: auto;margin-bottom: 20px">
          <span style="font-size: 20px">我是老师</span>
        </div>
      </div>
    </div>
    <div class="center_col_container hidden-md-and-up"
         style="padding: 5px;margin: 20px;width: 80%;background-color: #f8f8f8;border-radius: 5px"
         v-if="!has_selected">
      <span style="font-size: 20px">你当前的身份为游客</span>
      <span style="color: #8c939d;font-size: 16px">请选择你的身份</span>
      <div class="space_between_start_row_container" style="padding:5px">
        <div @click="selectRole('student')" class="center_col_container hover_shadow clickable_item identity_block">
          <img src="../../assets/small_e/correct2.jpg" style="width: 100%;height: auto;margin-bottom: 20px">
          <span style="font-size: 20px">我是学生</span>
        </div>
        <div @click="selectRole('teacher')" class="center_col_container hover_shadow clickable_item identity_block">
          <img src="../../assets/small_e/correct2.jpg" style="width: 100%;height: auto;margin-bottom: 20px">
          <span style="font-size: 20px">我是老师</span>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="center_col_container tf_et" v-if="mode==='student'"
           style="padding: 40px;margin: 20px;background-color: #ffffff;border-radius: 5px">
        <StudentRegister :is_role_register="true"/>
      </div>
      <template v-else>
        <template v-if="!has_teacher_selected">
          <div class="center_col_container tf_et" v-if="mode==='teacher'"
               style="padding: 40px;margin: 20px;background-color: #ffffff;border-radius: 5px" >
            <span style="font-size: 20px">请选择符合的情况</span>
            <span style="color: #8c939d;font-size: 16px">是否在EasyPaper登记注册过老师？</span>
            <div class="space_between_start_row_container" style="padding: 20px;">
              <div @click="selectTeacherRole(true)"
                   class="center_col_container hover_shadow clickable_item identity_block">
                <img src="../../assets/small_e/correct2.jpg" style="width: 100%;height: auto;margin-bottom: 20px">
                <span style="font-size: 20px">我已登记过老师</span>
              </div>
              <div @click="selectTeacherRole(false)"
                   class="center_col_container hover_shadow clickable_item identity_block">
                <img src="../../assets/small_e/wrong2.jpg" style="width: 100%;height: auto;margin-bottom: 20px">
                <span style="font-size: 20px">我尚未登记老师</span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="center_col_container tf_et" v-if="teacher_mode"
               style="padding: 40px;margin: 20px;background-color: #ffffff;border-radius: 5px">
            <div class="center_col_container" v-if="teacher_mode" style="width: 80%;text-align: center">
              <i class="el-icon-circle-check" style="font-size: 60px;color: green"></i>
              <span style="padding: 20px;font-size: 20px;font-weight: bold">已自动提交你的申请，请等待网站管理员处理</span>
            </div>
          </div>
          <div class="center_col_container" v-else
               style="padding: 40px;margin: 20px;width: 65%;background-color: #ffffff;border-radius: 5px">
            <div class="flex_start_col_container" style="width: 100%;">
              <!--            <span style="padding: 20px;font-size: 20px;font-weight: bold">点击顶部菜单”入驻平台“=>底部登记按钮即可录入信息！</span>-->
              <TeacherRegister width="100%" :is_role_register="true"/>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import StudentPage from "@/page/StudentPage";
import StudentRegister from "@/components/StudentRegister";
import TeacherRegister from "@/components/TeacherRegister";
import Vue from "vue";
import axios from "axios";

export default {
  name: "UserRoleRegister",
  components: {TeacherRegister, StudentRegister, StudentPage},
  data() {
    return {
      has_selected: false,
      has_teacher_selected: false,
      mode: "",
      teacher_mode: false
    }
  },
  methods: {
    selectTeacherRole(mode) {
      this.has_teacher_selected = true
      this.teacher_mode = mode
    },
    selectRole(mode) {
      this.has_selected = true
      this.mode = mode
    },
    back() {
      this.has_selected = false
    }
  }
}
</script>

<style scoped>
.identity_block {
  background-color: white;
  padding: 20px;
  margin: 20px
}

@media screen and (max-width: 600px) {
  /*小屏幕*/
  .tf_et {
    width: 90%;
  }
}

@media screen and (min-width: 600px) {
  /*大屏幕*/
  .tf_et {
    width: 35%;
  }
}
</style>