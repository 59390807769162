<template>
  <div class="page_basic_container" style="padding: 20px;padding-top: 40px" v-loading="loading">
    <StudentCenter v-show="user_type==='student'"/>
    <TeacherCenter v-show="user_type==='teacher'"/>
    <div class="center_col_container" v-if="!loading&&user_type!=='teacher'&&user_type!=='student'">
      <span style="font-size: 20px;font-weight: 500;padding: 15px">您的身份暂时无权查看本页面，请认证身份或刷新本页面重试！</span>
      <span style="font-size: 16px;color: dimgray;padding: 15px">如有疑问请联系网站管理员或EasyPaper小助手~</span>
      <img src="../../assets/small_e/wrong2.jpg" style="width: 200px;height: auto">
      <div style="padding: 20px">
        <el-button round type="primary" @click="refreshPage">刷新页面</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {get_lessons, get_combinations, get_student_info, get_user_center} from "@/utils/netschool_utils";
import crypto_utils from "@/utils/crypto_utils";
import {randomString} from "@/utils/utils";
import {get_single_lesson_payment_url} from "@/utils/payment_utils";
import BackgroundHeader from "@/components/BackgroundHeader";
import StudentCenter from "@/components/user_center/StudentCenter";
import TeacherCenter from "@/components/user_center/TeacherCenter";
import Vue from "vue";

export default {
  name: "UserCenter",
  components: {TeacherCenter, StudentCenter, BackgroundHeader},
  data() {
    return {
      lessons: [],
      combinations: [],
      display_index: "1",
      user: {},
      loading: false,
      user_type: ""
    }
  },
  mounted() {
    this.user_type = Vue.prototype.user_type
    get_user_center(this)
    let that = this
    this.$EventBus.$on("user_center", () => {
      get_user_center(that)
    });
    // get_combinations(this)
    // get_lessons(this)
    // get_student_info(this)
    // get_user_center(this)
  },
  methods: {
    refreshPage() {
      location.reload();
    }
  }
}
</script>

<style scoped>

</style>