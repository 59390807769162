<template>
  <div class="page_basic_container user_background center_col_container">
    <div class="center_col_container hover_shadow tf_et" v-loading="loading"
         style="padding: 40px;margin: 20px;background-color: white;border-radius: 5px">
      <template v-if="!loading">
        <template v-if="activate_success">
          <span style="padding: 20px;padding-bottom:0px;font-size: 25px">激活成功！</span>
          <img src="../../assets/small_e/correct2.jpg" style="width: 55%;height: auto;margin-bottom: 20px">
          <el-button @click="login">登陆（{{ countdown }}）</el-button>
        </template>
        <template v-else>
          <span style="padding: 20px;padding-bottom:0px;font-size: 25px">激活失败</span>
          <span style="padding: 20px;padding-bottom:0px;font-size: 16px;color: gray">未知的激活码</span>
          <img src="../../assets/small_e/wrong2.jpg" style="width: 55%;height: auto;margin-bottom: 20px">
        </template>
      </template>
      <template v-else>
        <span style="padding: 20px;padding-bottom:0px;font-size: 25px">激活中...</span>
        <span style="padding: 20px;padding-bottom:0px;font-size: 16px;color: gray">激活码验证中</span>
        <img src="../../assets/small_e/wrong2.jpg" style="width: 55%;height: auto;margin-bottom: 20px">
      </template>
    </div>
  </div>
</template>

<script>
import {user_activate, user_logout} from "@/utils/user_utils";

export default {
  name: "UserLogout",
  data() {
    return {
      countdown: 5,
      activate_success: false,
      loading: true
    }
  },
  mounted() {
    if (this.$route.query.code) {
      user_activate(this.$route.query.code, this)
    } else {
      this.loading = false
    }
    // user_logout(this)
  },
  methods: {
    login() {
      this.$router.push('/user/login')
    },
    startCountDown() {
      let that = this
      setInterval(function () {
        that.countdown -= 1
        if (that.countdown === 0) {
          that.$router.push("/user/login")
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>